import styled from "styled-components";
import { SectionContainer } from "../SectionContainer";
import tokens from "@/tokens";

export const AboutUsSectionContainer = styled(SectionContainer)`
  flex-direction: column;
  overflow-y: auto;
  background: linear-gradient( to right, rgba(195,77,1,0.85), rgba(195,77,1,0.85) ), url(assets/img/landing-area/world-championship.webp) center;
  max-width: unset;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding-bottom: 0;
  position: relative;
  z-index: 4;

  &::before{
    content:"";
    position:absolute;
    top:0;
    left:0;
    background: #000;
    width:100%;
    height:100%;
    opacity:0.3;
  }

  @media screen and (max-width: ${tokens.breakpoints.s}) {
    text-align:center;
  }

  h2 ,h2 span{
    position: relative;
    z-index: 4;
    margin-top: clamp(25px, 3vw, 40px);
    font-weight:900;
    letter-spacing: 0px;
    text-transform: uppercase;
    font-size: clamp(calc( (3rem) / 1.5), 5vw, 3rem);

    span.text-border {
      -webkit-text-stroke: 2px currentColor;
      -webkit-text-fill-color: transparent;
     }

   
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  max-height: 500px;
  overflow-y: auto;
  padding: 32px;

  ::-webkit-scrollbar-thumb {
    border: 9px solid #fff;
  }

  h3 {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    font-size: 20px;
    font-weight: 800;
    text-transform: capitalize;
    letter-spacing: 1.2px;
  }

  p {
    color: #fff;
  }
`;
