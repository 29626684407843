export const aboutText = [
  {
    title: "Sobre nós",
    paragraphs: [
      {
        text: "Era uma vez, 2 jogadores com experiência anterior em programação, design digital e suporte ao cliente que partiram em uma jornada com o objetivo de proporcionar aos jogadores uma experiência de jogo positiva e divertida. Criado e lançado em 2020, o Elojob Rise ve da união de líderes na indústria de boosting.",
      },
      {
        text: "Começamos nossa empresa oferecendo o boost no League of Legends. Então, no final de 2020, demos as boas-vindas ao boost no Valorant. Finalmente, no final de 2022, lançamos nosso serviço mais recente, o boost no Wild Rift.",
      },
      {
        text: "Agora estamos orgulhosos de trabalhar em todo o mundo com uma equipe dedicada de mais de 50 impulsionadores de alto nível, 4 representantes especializados em suporte ao cliente e 2 membros de back office que visam constantemente fornecer resultados da mais alta qualidade no menor período de tempo.",
      },
      {
        text: "Procuramos feedback regular de clientes, impulsionadores e outros funcionários do site sobre como melhorar nossos serviços de boost e a experiência do cliente. Com esse objetivo em mente, não nos limitamos a seguir as últimas tendências na indústria de boost, ainda almejamos a mesma coisa desde que começamos nossa aventura: ser inovadores, criadores de tendências e orientados para o cliente. Como exemplo de nossas conquistas, revolucionamos o mundo do boost ao ser o primeiro site de boost a adicionar um aumento de fila de duplas garantido. Desde então, continuamos a adicionar recursos e serviços de aumento de MMR que ajudam a melhorar a experiência do cliente em todos os sentidos, desde o momento em que nossos clientes entram no site até que seus serviços sejam concluídos.",
      },
      {
        text: "Obrigado por nos ajudar a crescer e por fazer parte da nossa comunidade.",
      },
    ],
  },
  {
    title: "A Melhor Experiência de Boost",
    paragraphs: [
      {
        text: "Do nosso ponto de vista, existem alguns fatores-chave que tornam um serviço excelente. Em primeiro lugar, um ótimo serviço deve ser confiável e consistente, proporcionando aos clientes a mesma experiência de alta qualidade sempre que o usarem. Também deve ser conveniente e fácil de usar, com instruções claras e um design amigável. Além disso, acreditamos que um bom serviço deve ser ágil e atento às necessidades de seus clientes, fornecendo suporte pronto e útil quando necessário. Por último, mas não menos importante, um ótimo serviço deve fornecer aos clientes serviços de boost de alta qualidade a um preço justo e razoável.",
      },
    ],
  },
  {
    title: "Os profissionais por trás do Boost",
    paragraphs: [
      {
        text: "Ser competitivo significa que você está disposto a se esforçar para ser o melhor em alguma coisa, seja um esporte, um jogo ou um trabalho. É uma qualidade importante se você pretende atingir seus objetivos e ser bem-sucedido. É isso que procuramos em nossos Boosters e é o que você pode esperar em nosso serviço de boost no League of Legends e Valorant.",
      },
      {
        text: "Ao longo dos anos, reunimos uma equipe de profissionais de alto escalão e certificados de boost na League vindos de todo o mundo, impulsionando o elo em mais de 10.000 pedidos. Cada um deles tem uma classificação LoL de Mestre, Grão-Mestre ou Desafiante, ou uma classificação Valorant acima de Imortal 3.",
      },
      {
        text: "Se você deseja aumentar seu MMR no LoL, fazer aumento de fila Duo com um jogador profissional ou até mesmo aprender a dominar o Flash e distribuir o tiro perfeito na cabeça, nossa lista de profissionais altamente treinados está aqui para fornecer a você um incrível impulso de elo 24 horas por dia, 7 dias por semana!",
      },
    ],
  },
  {
    title: "Um serviço de Boost em que você pode confiar",
    paragraphs: [
      {
        text: "Confiança e segurança são cruciais entre nós e nossos clientes. Sabemos que sua crença e confiança em nossa capacidade de fornecer um serviço de boost seguro e protegido. Esta é a base do nosso relacionamento com nossos clientes.",
      },
      {
        text: "Conosco, sua conta Valorant ou League of Legends estará protegida de qualquer dano. A única coisa melhor do que encontrar um boost no LoL barato ou um serviço de boost no Valorant de baixo custo é saber que você pode tirar um dia de folga e voltar para encontrar sua conta colocada em uma classificação alta, onde seu matchmaking o colocará em equipe com melhores companheiros de equipe.",
      },
      {
        text: "Através do Discord da Elojob Rise, todo o serviço de boost no LoL será rastreado para maior segurança, protegendo suas informações de login, registrando os Boosters no chat do jogo e até ativando uma VPN avançada configurada automaticamente para sua cidade.",
      },
      {
        text: "Incentivamos você a conferir as avaliações do Elojob Rise no nosso site, e também escrever uma avaliação após nossos serviços",
      },
      {
        text: "Todas as nossas análises são verificadas e agradecemos cada palavra do seu feedback, independentemente de termos te surpreendido com nosso boost no LoL ou levado você para Radiant em um boost no Valorant",
      },
      {
        text: "Nossos serviços de aumento de MMR são à prova de balas! (e de bombas também)!",
      },
      {
        text: "Solicite bate-papo e suporte ao vivo 24 horas por dia, 7 dias por semana <br /> A comunicação eficaz é da maior importância num contexto em que os clientes e o pessoal do local interagem regularmente. Acreditamos que uma boa comunicação ajuda a construir confiança e promover relacionamentos positivos, além de ajudar a evitar mal-entendidos e conflitos.",
      },
      {
        text: "Além disso, a comunicação eficaz é essencial para compartilhar ideias, dicas e truques sobre como ter um melhor desempenho no jogo, e muitas vezes é crucial para alcançar o sucesso, principalmente em um aumento na fila de duplas. Em última análise, a capacidade de se comunicar de forma eficaz é uma habilidade valiosa que pode beneficiar a experiência do boost.",
      },
    ],
  },
  {
    title: "Preço baixo, alta qualidade",
    paragraphs: [
      {
        text: "Preço e qualidade são dois fatores importantes que nossos clientes consideram ao tomar decisões de compra. O aumento de preços é normalmente visto como um indicador de valor para o dinheiro, e os clientes geralmente comparam os preços antes de decidir qual deles comprar. Estamos empenhados em oferecer preços de boost no League of Legends baratos e boost no Valorant barato, bem como preços competitivos",
      },
    ],
  },
];
