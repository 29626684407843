import React from "react";

import { aboutText } from "./constants";
import { Flex } from "../Flex/styles";
import { AboutUsSectionContainer, Content } from "./styles";

export const AboutSection = () => {
  return (
    <AboutUsSectionContainer>
      <h2>Conheça nossos <span className="text-border">valores</span></h2>
      <Content>
        {aboutText.map((item, index) => (
          <Flex flexDirection="column" key={`${item.title}-${index}`}>
            <div key={index}>
              <h3>{item.title}</h3>
            </div>
            <Flex flexDirection="column">
              {item.paragraphs.map((paragraph, index) => (
                <p
                  key={`${item.title}-paragraph-${index}`}
                  dangerouslySetInnerHTML={{ __html: paragraph.text }}
                />
              ))}
            </Flex>
          </Flex>
        ))}
      </Content>
    </AboutUsSectionContainer>
  );
};
